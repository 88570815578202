import React from 'react';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { api } from '../api';
import './styles.scss'

const antIcon = <LoadingOutlined style={{ fontSize: 100, color: '#0A1D9B' }} spin />;

export default class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    this.fetch(this.props.match.params.id ? this.props.match.params.id : '');
  }

  fetch = async (e) => {
    const decodedString = atob(e);     // Decode the String
    const obj = JSON.parse(decodedString)

    const clientes_contactos_validaciones_id = parseInt(obj.id);
    const validado = obj.validado;
    this.setState({ clientes_contactos_validaciones_id, validado, loading: true });

    try {
      const response = await api.clientes_contactos_validaciones.get(clientes_contactos_validaciones_id);
      if (response.status === "success") {
        var estado = response.data.clientes_contactos_validaciones.estado;
        this.setState({ estado });

        if (estado !== 'C') {
          const response = await api.clientes_contactos_validaciones.validar({
            Clientes_contactos_validaciones_id: parseInt(clientes_contactos_validaciones_id),
            validado: validado
          });
          if (response.status === "success") {
            this.setState({ estado: validado });
          } else {
            message.error(response.message, 7);
          }
        }
      } else {
        message.error(response.message, 7);
      }

    } catch (e) {
      message.error(e.toString(), 7);
    } finally {
      this.setState({ loading: false });
    }
  }


  irAPagina = () => {
    window.open(`https://credifin.com.ar/`, "_blank");
  }

  render() {

    return (
      <>
        <section >
          <div className="container d-flex justify-content-center">
            {typeof this.state.estado === "undefined" &&
              <Spin indicator={antIcon} size="large"/>
            }
            {this.state.estado === "C" &&
              <img src="assets/img/completado.png" className="img-fluid imagen" onClick={() => this.irAPagina()} />
            }
            {this.state.estado === "V" &&
              <img src="assets/img/si.png" className="img-fluid imagen" onClick={() => this.irAPagina()} />
            }
            {this.state.estado === "R" &&
              <img src="assets/img/no.png" className="img-fluid imagen" onClick={() => this.irAPagina()} />
            }
          </div>
        </section>

        <footer class="fixed-bottom">
          <div class="container">
            <div class="row text-center">
              <div class="col-md-6">
                <span className="text-social-media"> Seguinos en:</span>
                <a href="https://www.facebook.com/credifinlogisticaok/" target="_blank" and rel="noopener noreferrer"><i class="fab fa-facebook social-media-icons"></i></a>
                <a href="https://www.instagram.com/credifinlogistica/" target="_blank" and rel="noopener noreferrer"><i class="fab fa-instagram social-media-icons"></i></a>
              </div>
              <div class="col-md-6">
                <a href="https://credifin.com.ar/" target="_blank" rel="noopener noreferrer" className="link-page">www.credifin.com.ar</a>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
