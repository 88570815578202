import 'isomorphic-fetch';
import { config } from './config'

export const api = {
  clientes_contactos_validaciones: {
    async validar(data) {
      const response = await fetch(config.URL_API + '/clientes_contactos_validaciones/validaremail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      return responseData;
    },
    async get(id) {
      const response = await fetch(config.URL_API + `/clientes_contactos_validaciones/${id}`, {
        method: 'GET',
      });
      const data = await response.json();
      return data;
    },
  },
}
